var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.entities},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('src\\views\\servertasks\\list.2090')))]),_c('v-spacer'),(_vm.user.adminAccess && _vm.user.adminAccess.server_tasks >= _vm.ACCESS.WRITE)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.create}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t('src\\views\\servertasks\\list.3208')))],1):_vm._e()],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createdAt,'DD.MM.YYYY HH:mm'))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.SERVER_TASK_TYPE_TITLE[item.type]))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.SERVER_TASK_STATUS_TITLE[item.status]))+" "),(_vm.user.adminAccess && _vm.user.adminAccess.server_tasks >= _vm.ACCESS.WRITE)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('v-list',_vm._l((_vm.SERVER_TASK_STATUS_TITLE),function(status,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.setStatus(item._id, index)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(status)))])],1)}),1)],1):_vm._e()]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.edit(item._id)}}},[_vm._v("mdi-pencil")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }